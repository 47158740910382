import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AccountPortalAuthService } from '../auth.service';
import { CookieService } from 'ngx-cookie-service';
// import { AuthService } from '@sbd-account-portal/app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AwaitingMFAGuard implements CanMatch
{

  continue: boolean = false;
    /**
     * Constructor
     */
    constructor(
        private _authService: AccountPortalAuthService,
        private _cookieService : CookieService,
        private _router: Router
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this._check();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @private
     */
    private _check(): Observable<boolean | UrlTree>
    {
      this._cookieService.delete('accessToken')
      return this._authService.checkIfUserAccountRequiredMFA().pipe(
        switchMap((canContinue: boolean) => {
          if (!canContinue) {
            return of(this._router.createUrlTree(['/sign-in']));
          }
          return of(canContinue);
        })
      );
    }
}
