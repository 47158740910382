import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, catchError, of, throwError } from 'rxjs';
import { AccountPortalAuthService } from '../core/auth/auth.service';
import { environment } from '../environments/environment';
import { CathyService } from '../services/cathy.service';

/** Inject With Credentials into the request */
@Injectable()
export class PermissionsInterceptor implements HttpInterceptor {
  private route: ActivatedRoute;

  constructor(
    private _authService: AccountPortalAuthService,
    private _cathyService: CathyService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
      this.route = this._activatedRoute;
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(catchError(err => {
      // const url = '/' + this.route.pathFromRoot.map(r => r.snapshot.url).filter(f => !!f[0]).map(([f]) => f.path).join('/');
      // alert(url);

      // return(of(false));
      if (![401, 403].includes(err.status)) {
        const error = err.error?.message || err.statusText;
        // console.error(err);
        return throwError(() => error);
      }

      if ([401].includes(err.status)) {
        this._router.navigate(['/operations/accounting/ap-ar-carrier-notes/create']);
        // window.location.href = `${environment.dougV2BaseUrl}/admin/?nextURL=${encodeURIComponent(window.location.href)}`
      }

      if ([403].includes(err.status)) {
        // this._cathyService.error('Permission denied.');
        return of(new HttpResponse({ body: {}, status: 200 }));
      }

      return of(err);
    }))
  }
}
