import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { environment } from '@sbd-account-portal/app/environments/environment';
import { CathyComponent } from './cathy.component';

@Injectable({
  providedIn: 'root'
})
export class CathyService {
  snackBar = inject(MatSnackBar);

  messages = {
    "success": ['You Betcha.', 'Shur Thang.', 'You Win!', 'Terrific!', 'Wonderful!', 'Well, isn\'t that wonderful!?!?', 'I\'m so proud of you!', 'Great work, now back to work.', 'Well done. Well done indeed.', 'You are exceptional in many ways.', 'Perfecto.', 'Nice one!', 'Woo Hoo!', 'YES!!!', 'Dreams really do come true.', 'Winner Winner Chicken Dinner (side of Kale).', 'You are good at this.', 'A for effort... wait, there aren\'t any A\'s in e-f-f-o-r-t!', 'Swell.', 'Don\'t let it go to your head, but nice work.', 'Check that off the list.', 'All done!', 'Percent success: 100.', 'That worked.', 'With little fanfare you saved the day.', 'Give yourself a high 5.', 'You did it!', 'That\'s done.', 'Cross that off your list.', 'Another winning click.', 'Yikes! Just kidding, it worked.', 'That did the trick.', 'BAM!', 'That right about wraps it up.', 'Awesome Sauce!', 'You WIN!', 'Most excellent, dude!', 'w00t!', 'That\'s a wrap.', 'Good on ya!', 'Tasty!', 'Supercalifragilisticexpialidocious.', 'First-rate work there.', 'Just superb.', 'Exemplary.', 'You have really out-done yourself this time.', 'You deserve some bling for that.', 'You are really something special.', 'Yes, Yes, Yes!', 'On to other things, your work here is done.'],
    "error": ['Darny, darn, darn', 'Run for cover!', 'Oops!', 'I guess you aren\'t quite as clever as you thought you were.', 'If at first you don\'t succeed you must still try.', 'Nope.', 'That\'s not it', 'Give it another go. Maybe click harder this time.', 'Hi there, I\'m failure. Nice to meet you.', 'Yikes! That was terrible.', 'I can\'t do it, I just can\'t!', 'Wrong again... getting used to that?'],
    "greeting": ['Smile and the world smiles with you.', 'Have a great day', 'You miss 100% of the shots you never take', 'Make today a great day'],
    "saving": ['Let\'s save that so we don\'t forget it', 'Choking down another save', 'Me? OK, I\'ll save it.', 'I\'ll get right on that!', 'Save rhymes with Dave.  Just saying.', 'Saves away!', 'I hope this is important, because it is a bit of a pain.', 'And away it goes...', 'Doing some cool things with ones an zeros', 'Shot - SAVE!', 'I\'m just asking you to save me', 'Saving like a boss!', 'Saving away again in Margaritaville', 'If I\'m hearing you correct, I should save this, right?', 'Going to put that in the attic for you in case you need it later.', 'Saved the day[ta]', 'Fine. I can save it.', 'Oh, please let me do all the work to save that.']
  };

  constructor(private _matSnackBar: MatSnackBar) { }

  successMessage() {
    return this.messages.success[Math.floor(Math.random() * this.messages.success.length)];
  }

  errorMessage() {
    return this.messages.error[Math.floor(Math.random() * this.messages.error.length)];
  }

  greetingMessage() {
    return this.messages.greeting[Math.floor(Math.random() * this.messages.greeting.length)];
  }

  savingMessage() {
    return this.messages.saving[Math.floor(Math.random() * this.messages.saving.length)];
  }

  show(message: string, action?: string, config?: MatSnackBarConfig): MatSnackBarRef<unknown> {
    config = config || <MatSnackBarConfig>{};
    config.duration = config.duration || 2500;
    config.data.message = config.data.message || message || '';

    return this._matSnackBar.openFromComponent(CathyComponent, config);
    // return this.snackBar.open(message, action, config);
  }

  success(message?: string) {
    message = message || '';
    return this.show(('' + message).trim(), null, { duration: 2500, panelClass: 'success-snackbar', data: { header: this.successMessage() } });
  }

  error(message?: string) {
    message = message || '';
    return this.show(('' + message).trim(), null, { duration: 7500, panelClass: 'error-snackbar', data: { header: this.errorMessage() } });
  }

  warning(message?: string) {
    message = message || '';
    return this.show(('' + message).trim(), null, { duration: 7500, panelClass: 'warning-snackbar', data: { header: this.errorMessage() } });
  }

  info(message?: string) {
    message = message || '';
    return this.show(('' + message).trim(), null, { duration: 2500, panelClass: 'info-snackbar', data: { header: this.errorMessage() } });
  }
}
